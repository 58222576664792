<template>
  <section class="Big">
    <div class="bg" @wheel="scrollInit($event)">

      <div ref="scroll_content_A0" class="page">
        <div class="self_intro">
          <div>
            <div class="self_title">{{ main1.title1 }}</div>
            <div class="self_title_middle">{{ main1.title2 }}</div>
          </div>

          <div class="text_box">
            <div class="text">{{ main1.title3 }}</div>
          </div>
        </div>
      </div>

      <div class="navigation_bar" ref="navigation_bar"
        @click="navigation($event)">
      </div>
      <div class="page " id="page2" ref="scroll_content_A1" style="width: 60%;">
        <div class="detail_intro"
          style="width: 100%;height: 100%; position: relative;">
          <div
            style="position: absolute;width: 100%;text-align: center;top: 20px;font-size: 35px;color: white;">
            关于我</div>
          <div class="detail_intro_left">
            <div class="pic">
              <span
                style="font-size: 25px;color: white;;line-height: 50px;">Web前端开发人员，</span>
              <br>
              <p style="font-size: 20px;color: white;">

                {{ about_me }}
              </p>
              <div style="height: 50%;display: flex;align-items: center;">
                <div style="flex: 1;">
                  <p
                    style="font-size: 28px;text-align: center;color: white;font-weight: 700;line-height: 46px;">
                    {{ myself.age }}
                  </p>
                  <p style="text-align: center;color: white;">年龄</p>
                </div>
                <div style="flex: 1;">
                  <p
                    style="font-size: 28px;text-align: center;color: white;font-weight: 700;line-height: 46px;">
                    {{ myself.work_year }}
                  </p>
                  <p style="text-align: center;color: white;">工作经验</p>
                </div>
              </div>
            </div>
            <div class="text">
              <div style="color: white;margin-bottom: 15px;"
                v-for="obj in skill_list" :key="obj.name">
                <div style="font-size: 20px;margin-bottom:3px">{{obj.name}}
                </div>
                <el-progress :percentage="obj.num" status="exception"
                  :show-text="false"></el-progress>
              </div>
            </div>
          </div>

          <div class="threeD" style="color:rgb(122, 24, 24)">
            <MyOfficeScene></MyOfficeScene>
          </div>

        </div>
      </div>
      <div class="page" id="page2" ref="scroll_content_A2"
        style="position: relative;">
        <div
          style="width: 900px;height: 68%; display: flex;flex-direction: column;justify-content: center;align-items: center;">

          <div
            style="width: 100%;position:absolute;top:20px;margin-bottom:10px;text-align: center;font-size: 35px;letter-spacing: 10px;color: white;">
            项目展示
          </div>
          <div style="width: 100%;flex:1;display: flex;flex-direction: column;">

            <el-carousel indicator-position="outside"
              style="height: 100%;display: flex;flex-direction: column;">
              <el-carousel-item v-for="(item,ind) in project_show_list"
                :key="ind" style="cursor: pointer;">
                <div
                  style="border: 1px solid rgb(0,0,0,.5);;width: 100%;height: 100%;position: relative;background-repeat: no-repeat;"
                  class="project_show" @click="open_window(item.url)">
                  <div
                    :style="`width: 100%;height: 100%;background-image: url(${item.img});background-size:100% 100%;background-repeat: no-repeat;`">
                  </div>
                  <div style="" id="a1">

                    <div style="padding: 10px;font-size: 25px;">
                      项目名称：<span
                        style="color:#ea2525;font-weight: 700;">{{item.name}}</span>
                    </div>
                    <div style="font-size: 20px;margin:10px;">
                      技术栈：<span>{{item.text}}</span>
                    </div>
                    <div style="font-size: 16px;margin:10px;line-height: 26px;">
                      项目简介：<span>{{item.ability}}</span>
                    </div>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>

          </div>
        </div>
      </div>
      <div class="page" id="page2" ref="scroll_content_A3"
        style='position: relative;'>
        <div style="width: 800px;height: 60%; ">
          <div
            style="width: 100%;text-align: center;position: absolute;top: 20px;font-size: 35px;color: white;">
            联系我
          </div>
          <div
            style="display: flex;color:white;height: 100%;line-height: 23px;font-size:25px;flex-direction: column;justify-content: center;align-items: center;">
            <div v-for="(obj,ind) in contact_me" :key="obj.name"
              style="position:relative;margin-bottom: 20px;">
              <span>{{obj.name}}
                <i :class="`iconfont ${obj.icon}`" style="font-size: 25px;"></i>
              </span>
              <div style="margin-top: 5px;">
                <el-tooltip class="item" effect="dark"
                  :content="`点击复制${obj.name}`" placement="top-end">
                  <input
                    style="font-size: 25px;display: block;width: auto;background-color: rgba(0,0,0,0);color: #fff;border: none; cursor: pointer;"
                    @click="copy(obj.val,`input${ind}`)" readonly type="text"
                    :ref="`input${ind}`" :value="obj.val">
                </el-tooltip>
              </div>
            </div>
            <span style="">微信二维码</span>
            <img src="./static/微信二维码.jpg"
              style="width: 150px;height: 150px;margin-top: 20px;" alt="">
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import MyOfficeScene from './components/MyOfficeScene.vue'
import './font/iconfont.css'
import './css/index.css'
export default {
  name: '',
  data () {
    return {
      main1: {
        title1: "Hello , I'm ZhangHe",
        title2: "一个在前端路上永远满腔热血的小白",
        title3: "我把镜子顶在头上，雨水以为他们要落向另一片天空​。",
      },
      pageIndex: 0,
      scrollFlag: true,
      project_show_flag: false,
      about_me: ' 从事Web开发，崇拜技术大佬，喜欢折腾，对前端技术有热情，希望日后也成为技术大佬。',
      myself: { age: 24, work_year: '02+' },
      project_show_list: [
        {
          name: 'PC端音乐播放页面',
          text: 'Vue + VueRouter + Vuex + Element-UI组件库 + Axios',
          img: require('./static/bg1.png'),
          url: 'http://music.zh11123.cn',
          ability: `该项目为在线音乐播放项目。后端是github开源网易云接口node项目，实现了播放音乐，搜索音乐，收藏音乐，下载音乐，查看歌手个人简介、专辑、MV ...等功能`
        },
        {
          name: '自定义大屏',
          text: 'Vue + VueRouter + Vuex + Element-UI组件库 + Axios + vue-grid-layout + DataV',
          img: require('./static/bg2.png'),
          url: 'http://drag.zh11123.cn',
          ability: `该项目为自定义大屏项目，利用vue的核心思想，数据驱动视图，
          实现了拖拽并放置卡片，配置卡片样式，配置数据，保存、回显、覆盖方案...等功能。`
        },
        {
          name: '在线代码编辑',
          text: 'Vue + VueRouter + Vuex + Element-UI组件库 + Axios + monaco-editor + node',
          img: require('./static/bg3.png'),
          url: 'http://onc.zh11123.cn',
          ability: `该项目可上传html文件并利用monaco-editor展示代码，在线编辑代码后可实时看到代码的运行结果。
          实现了下载模板代码压缩包，上传模板代码，在线编辑并运行代码，删除案例...等功能。`
        },
      ],
      skill_list: [{ name: 'html', num: 75 }, { name: 'css', num: 60 },
      { name: 'less', num: 50 },
      { name: 'js', num: 65 }, { name: 'jquery', num: 65 }, { name: 'vue', num: 65 },
      ],
      contact_me: [
        { name: '电话', icon: "icon-31dianhua", val: '17602282235' },
        { name: '微信', icon: "icon-weixin", val: 'Z17602282235' },
        { name: 'QQ ', icon: "icon-QQ", val: '2810668214' },
        { name: '邮箱', icon: "icon-youxiang", val: '2810668214@qq.com' }
      ]
    };
  },
  created () {
  },
  mounted () {
    // dom元素刚加载好时，去除滚动条，根据页面多少添加滚动点
    this.stopMove();
    let navigation_point = ''
    document.querySelectorAll('.page').forEach((val, ind) => {
      navigation_point = `${navigation_point}<div class="navigation_point" id=${ind}></div>`
    })
    this.$refs.navigation_bar.innerHTML = navigation_point
    this.$refs['scroll_content_A' + '0'].scrollIntoView({ behavior: 'smooth', block: 'center' });
    document.querySelectorAll('.navigation_point')[0].classList.add('navigation_pointer')
    this.scrollInit()
  },
  methods: {
    copy (str, dom) {
      // console.log(str, this.$refs[dom][0])
      this.$refs[dom][0].focus()
      this.$refs[dom][0].select()
      document.execCommand('copy')
      this.$message({
        message: '复制成功！',
        type: 'success',
        offset: 101,
        duration: 700,
        center: true
      });
    },
    open_window (url) {
      // console.log('点击', str)
      window.open(url);
    },
    scrollInit (e) {
      // 页面滚动逻辑，
      let timer = null
      if (e == undefined || this.scrollFlag == false) return
      let pageList = document.querySelectorAll('.page')
      clearTimeout(timer)
      if (e.wheelDelta < 0) {
        this.scrollFlag = false
        this.pageIndex = this.pageIndex >= pageList.length - 1 ? pageList.length - 1 : Number(this.pageIndex) + 1
        timer = setTimeout(() => {
          this.scrollFlag = true
        }, 500);
      } else {
        this.scrollFlag = false
        this.pageIndex = this.pageIndex <= 0 ? 0 : this.pageIndex - 1
        timer = setTimeout(() => {
          this.scrollFlag = true
        }, 500);
      }
      this.$refs['scroll_content_A' + this.pageIndex].scrollIntoView({ behavior: 'smooth', block: 'center' });
      document.querySelectorAll('.navigation_point').forEach((val) => {
        val.classList.remove('navigation_pointer')
      })
      document.querySelectorAll('.navigation_point')[this.pageIndex].classList.add('navigation_pointer')
    },
    stopMove () {
      //可以阻止页面出现滚动条
      let m = function (e) { e.preventDefault(); };
      document.body.style.overflow = 'hidden';
      document.addEventListener("touchmove", m, { passive: false });//禁止页面滑动
    },
    navigation (e) {
      // 点击滚动点
      if (e.target.classList == 'navigation_bar') return
      document.querySelectorAll('.navigation_point').forEach((val) => {
        val.classList.remove('navigation_pointer')
      })
      this.pageIndex = e.target.id
      this.$refs['scroll_content_A' + this.pageIndex].scrollIntoView({ behavior: 'smooth', block: 'center' });
      e.target.classList.add('navigation_pointer')

    },

  },
  components: { MyOfficeScene },

  props: {},

  watch: {},

  computed: {},

}

</script>
<style  >
</style>