<template>
  <div class="three-container" ref="divDom"></div>
</template>
<script>
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import gsap from 'gsap'
export default {
  data () {
    return {
      size: {
        width: 0,
        height: 0,
        aspect: 1,
        frustrum: 10,
        pixelRatio: Math.min(window.devicePixelRatio, 2),
      },
      camera: new THREE.OrthographicCamera(),
      renderer: new THREE.WebGLRenderer({
        alpha: true,
      }),
      scene: new THREE.Scene(),
      sunLight: new THREE.DirectionalLight(0xffffff),
      ambient: new THREE.AmbientLight(0xffffff, 0.1),
      pointLight: new THREE.PointLight(0xffffff)

    }
  },
  created () {
    this.init()
  },
  mounted () {
    this.initSize()
    this.animate()
    this.switchTheme()
    this.$refs.divDom.appendChild(this.renderer.domElement)
  },
  methods: {
    init () {
      const scene = this.scene
      /*==================== 创建一个渲染器 ====================*/
      // const renderer = new THREE.WebGLRenderer({
      //   alpha: true,
      // })
      this.renderer.useLegacyLights = true
      this.renderer.outputEncoding = THREE.sRGBEncoding
      this.renderer.shadowMap.enabled = true
      this.renderer.shadowMap.type = THREE.PCFSoftShadowMap
      /*==================== 加载模型 ====================*/
      let chair = null,
        screen = null
      // 设置椅子旋转
      const chairAnimate = () => {
        gsap.to(chair.rotation, {
          y: 0.7,
          duration: 10,
          ease: 'power1.inOut',
          repeat: -1,
          yoyo: true,
        })
      }
      // mac屏幕播放视频
      const setScreen = () => {
        const video = document.createElement('video')
        video.src = '3D/kda.mp4'
        video.muted = true
        video.playsInline = true
        video.autoplay = true
        video.loop = true
        video.play()
        const videoTexture = new THREE.VideoTexture(video)
        videoTexture.minFilter = THREE.NearestFilter
        videoTexture.magFilter = THREE.NearestFilter
        videoTexture.generateMipmaps = false
        videoTexture.encoding = THREE.sRGBEncoding
        // console.log(screen.material)
        screen.material = new THREE.MeshStandardMaterial({
          map: videoTexture,
        })
      }
      // 加载glb模型
      const gltfLoader = new GLTFLoader()
      const dracoLoader = new DRACOLoader()
      dracoLoader.setDecoderPath('/draco/')
      gltfLoader.setDRACOLoader(dracoLoader)
      gltfLoader.load('/3D/officeScene.glb', (glb) => {
        // console.log(glb.scene)
        glb.scene.scale.set(1.8, 1.8, 1.8)
        glb.scene.position.y = -1.5
        // 获取椅子和电脑屏幕用于添加动画
        glb.scene.children.forEach((item) => {
          item.castShadow = true
          item.receiveShadow = true
          if (item.name === 'Chair') {
            chair = item
          } else if (item.name === 'mac-screen') {
            screen = item
          }
        })
        chairAnimate()
        setScreen()
        scene.add(glb.scene)
        this.renderer.render(scene, this.camera)
      })
      /*==================== 创建相机 ====================*/
      // const camera = new THREE.PerspectiveCamera(45, 1, 1, 1000)
      // const camera = new THREE.OrthographicCamera()
      //设置相机位置
      this.camera.position.set(-9.72, 5.27, -2.25)
      //设置相机方向
      this.camera.lookAt(0, 0, 0)
      /*==================== 创建灯光 ====================*/
      // 环境光
      // const ambient = new THREE.AmbientLight(0xffffff, 0.5)
      const ambient = this.ambient
      scene.add(ambient)
      // 太阳光
      // const sunLight = new THREE.DirectionalLight(0xffffff)
      const sunLight = this.sunLight
      sunLight.castShadow = true
      sunLight.shadow.camera.far = 20
      sunLight.shadow.mapSize.set(2048, 2048)
      sunLight.shadow.normalBias = 0.05
      sunLight.position.set(-1.5, 7, 3)
      scene.add(sunLight)
      // 台灯光
      // const pointLight = new THREE.PointLight(0xffffff)
      const pointLight = this.pointLight
      pointLight.castShadow = true
      pointLight.position.set(0.6, 4, -2.3)
      pointLight.shadow.camera.far = 20
      pointLight.shadow.mapSize.set(2048, 2048)
      pointLight.shadow.normalBias = 0.05

      scene.add(pointLight)
      // 调试器
      // const size = {
      //   width: 0,
      //   height: 0,
      //   aspect: 1,
      //   frustrum: 10,
      //   pixelRatio: Math.min(window.devicePixelRatio, 2),
      // }
      // 获取页面的实例对象
      // const pageInstance = getCurrentInstance()
      // const initSize = () => {
      //   size.width = pageInstance.refs.divDom.offsetWidth
      //   size.height = pageInstance.refs.divDom.offsetHeight
      //   size.aspect = size.width / size.height
      //   size.pixelRatio = Math.min(window.devicePixelRatio, 3)
      //   // camera.aspect = size.aspect
      //   camera.left = (-size.aspect * size.frustrum) / 2
      //   camera.right = (size.aspect * size.frustrum) / 2
      //   camera.top = size.frustrum / 2
      //   camera.bottom = -size.frustrum / 2
      //   camera.updateProjectionMatrix()
      //   renderer.setSize(size.width, size.height)
      //   renderer.setPixelRatio(size.pixelRatio)
      // }
      /*==================== 控制器 ====================*/
      const controls = new OrbitControls(this.camera, this.renderer.domElement)
      controls.maxAzimuthAngle = -1
      controls.minAzimuthAngle = Math.PI * 1.2
      controls.maxPolarAngle = Math.PI / 3 //控制器垂直方向最大旋转角度（理解为逆时针旋转角度）
      controls.minPolarAngle = Math.PI / 6 //控制器垂直方向最大小旋转角度（理解为顺时针旋转角度）
      controls.enableZoom = false
      /*==================== 动画 ====================*/
      // const animate = () => {
      //   requestAnimationFrame(animate)
      //   renderer.render(scene, camera)
      // }
      /*==================== 切换主题 ====================*/
      // const theme = useThemeState().theme
      // const theme = null
      // const switchTheme = () => {
      //   if (theme.value === 'light') {
      //     gsap.to(sunLight, {
      //       intensity: 0.78,
      //     })
      //     gsap.to(ambient, {
      //       intensity: 0.78,
      //     },)
      //     gsap.to(pointLight, {
      //       intensity: 0,
      //     })
      //     gsap.to(ambient.color, {
      //       r: 1,
      //       g: 1,
      //       b: 1,
      //     })
      //   } else {
      //     gsap.to(sunLight, {
      //       intensity: 0,
      //     })
      //     gsap.to(ambient, {
      //       intensity: 1.03,
      //     })
      //     gsap.to(pointLight, {
      //       intensity: 1.68,
      //     })
      //     gsap.to(ambient.color, {
      //       b: 0.9568627450980393,
      //       g: 0.24313725490196078,
      //       r: 0.3607843137254902
      //     })
      //   }
      // }
    },
    initSize () {
      this.size.width = this.$refs.divDom.offsetWidth
      this.size.height = this.$refs.divDom.offsetHeight
      this.size.aspect = this.size.width / this.size.height
      this.size.pixelRatio = Math.min(window.devicePixelRatio, 3)
      // camera.aspect = size.aspect
      this.camera.left = (-this.size.aspect * this.size.frustrum) / 2
      this.camera.right = (this.size.aspect * this.size.frustrum) / 2
      this.camera.top = this.size.frustrum / 2
      this.camera.bottom = -this.size.frustrum / 2
      this.camera.updateProjectionMatrix()
      this.renderer.setSize(this.size.width, this.size.height)
      this.renderer.setPixelRatio(this.size.pixelRatio)
    },
    animate () {
      requestAnimationFrame(this.animate)
      this.renderer.render(this.scene, this.camera)
    },
    switchTheme () {
      gsap.to(this.sunLight, {
        intensity: 0,
      })
      gsap.to(this.ambient, {
        intensity: 1.03,
      })
      gsap.to(this.pointLight, {
        intensity: 1.68,
      })
      gsap.to(this.ambient.color, {
        r: 0.8,
        g: 0.1,
        b: 0.1,
      })
    }
  }
}
</script>

<style  scoped>
.three-container {
  width: 100%;
  height: 100%;
}
</style>